import axios from 'axios';

import { getData, useToken } from './interceptors';

import store from './store';

const authApi = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API_URI,
  showLoader: true,
});

const myApi = axios.create({
  baseURL: process.env.VUE_APP_MY_API_URI,
  showLoader: true,
});

// Add token to request
authApi.interceptors.request.use(useToken);
myApi.interceptors.request.use(useToken);

// Extract data from response
authApi.interceptors.response.use(getData);
myApi.interceptors.response.use(getData);

const api = {
  airport:{
    get: (id) => myApi.get(`/api/airport/${id}`),
    getDefault: (id) => myApi.get(`/api/default_airport/${id}`),
    getUserAirports: (id) => myApi.get(`/api/user_airports/${id}`),
    getEvents: (id) => myApi.get(`/api/airport/events/${id}`)
  },
  deleteAccountDemand: (email) => myApi.post('/delete_account', { email }),
  weather: {
    getModels: (geoname) => myApi.get(`/model/${geoname}`),
    get: () => myApi.get('/rainviewer/weather'),
  },
  documents:{
    post: (data) => myApi.post('/documents', data),
  },
  users: {
    getAll: () => authApi.get('/users', { params: { per_page: 100000 } }),
    get: (id, flag) => {
        const params = flag ? { flag } : {};
        return myApi.get(`/users/${id}`, { params });
    },
    me: () => authApi.get('/user/me'),
    getUploadedFiles: (id_user) => myApi.get(`/users/uploaded_files/${id_user}`),
    subsfavorite: (id_user, data) => myApi.post(`/users/${id_user}/favorites`, data),
    unsubsfavorite: (id_user, id_favoris) => myApi.delete(`/users/${id_user}/favorites/${id_favoris}`),
    updateData: (id, data) => myApi.put(`/users/${id}`, data),
    updateLang: (id, lang) => myApi.put(`/update_lang/${id}/${lang}`),
    updateEmail: (id, data) => myApi.put(`/users/${id}/change_email`, data),
    updatePassword: (id, data) => myApi.put(`/users/${id}/change_password`, data),
    updateRole: (id_user, id_role) => myApi.put(`/update_role/${id_user}/${id_role}`),
    lostPassword: (email, params) => authApi.get(`/users/${email}/lost_password${params}`),
  },
  cities: {
    search: (search) => myApi
      .get('/cities', { params: { search } })
      .then(value => value.sort((a, b) => 
        a.iso === "FR" && b.iso !== "FR" ? -1
        : a.iso !== "FR" && b.iso === "FR" ? 1
        : a.nom < b.nom ? -1
        : 1
    )),
    getByCoordinates: (lat, lng) => myApi.get(`/city/${lat}/${lng}`),
    getinfos: (geoname) => myApi.get(`/city/${geoname}`),
  },
  stations: {
    getOne: (id_station, from, to) => myApi.get(`/stations/${id_station}/data${from ? `?from=${from}&to=${to}` : ''}`),
    getAll: (id_stations, from, to) => myApi.get(`/stations/list/${id_stations}/data${from ? `?from=${from}&to=${to}` : ''}`),
    getExportUrl: (id_station, from, to) => `${myApi.defaults.baseURL}/stations/${id_station}/export${from ? `?from=${from}&to=${to}` : ''}`,
    getPublics: () => myApi.get('/stations/public'),
    getCountries: () => myApi.get('/countries'),
    getTimezones: () => myApi.get('/timezones'),
    getConnectors: () => myApi.get('/stations/connectors'),
    createStation: (id_user, data) => myApi.post(`/users/${id_user}/stations/connect`, data),
    activate: (id_station) => myApi.get(`/stations/${id_station}/activate`),
    disable: (id_station) => myApi.get(`/stations/${id_station}/disable`),
    subscribe: (id_station, id_user) => myApi.get(`/users/${id_user}/stations/${id_station}/subscribe`),
    unsubscribe: (id_station, id_user) => myApi.get(`/users/${id_user}/stations/${id_station}/unsubscribe`),
  },
  notifications: {
    post: (id_user, token) => myApi.post('/notifications', { id_user, token }),
  },
  forecast: {
    get: (geoname, freq = 3, model = null) => myApi.get(`/forecasts/${geoname}/${process.env.VUE_APP_WEATHER_MODEL || store?.state?.user?.modele_defaut || model || 'false'}?freq=${freq}`),
    getMulti: (geoname) => myApi.get(`/forecasts/${geoname}/get/multi`),
    getByLatLng: (lat, lng) => myApi.get(`/forecastsByLatLng/${lat}/${lng}`),
    getAlerts: (id_user, geoname) => myApi.get(`/users/${id_user}/alerts_level/${geoname}`),
    getText:(search) => myApi.get(`/meteo-txt/${search}`)
  },
  roadcast: {
    get: (lat, lng) =>
      axios
        .get(`https://roadcast.previmeteo.net/previmeteo/${lat}/${lng}`)
        .then(getData),
  },
  alert: {
    get: (id_user) => myApi.get(`/users/${id_user}/alerts/list`),
    getMapAlerts: (id_user) => myApi.get(`/users/${id_user}/alerts`),
    createAlert: (id_user, geoname, data) => myApi.post(`/users/${id_user}/geoname/${geoname}/alerts`, data),
    updateAlert: (id_user, id_alert, data) => myApi.put(`/users/${id_user}/alerts/${id_alert}`, data),
    deleteAlert: (id_user, id_alert) => myApi.delete(`/users/${id_user}/alerts/${id_alert}`),
    readAll: (id_user) => myApi.post(`/users/${id_user}/alerts/read`),
  },
  alertsairport:{
    get: (airport) => myApi.get(`/api/alerts-airport`, { params: { airport } }),
    post: (data) => myApi.post(`/api/alerts-airport`, data),
    delete: (id) => myApi.delete(`/api/alerts-airport/${id}`),
  },
  bulletin: {
    get: (cityname) => myApi.post('/bulletin', { cityname }),
    subscribe: (id_user, data) => myApi.post(`/users/${id_user}/bulletins_pdf`, data),
    unsubscribe: (id_user, id_bulletin) => myApi.delete(`/users/${id_user}/bulletins_pdf/${id_bulletin}`),
    meteorologist: (poi) => myApi.get(`/api/airport/bulletinMeteorologist/${poi}`),
  },
  referrer: {
    get: (user_id) => myApi.get(`/users/${user_id}/referrers`),
    invite: (data) => myApi.post('/invite', data),
  },
  sendMagicLink: (email, flag) => authApi.post('/auth/magic/send', { email:email, flag:flag }),
  verifyMagicToken: (token) => authApi.post('/auth/magic/verify', { token }),
  loginAs: (email) => authApi.post('/auth/local', { email }),
  login: (email, password, flag) => authApi.post('/auth/local', { email, password, flag }),
  registerUser: (data) => authApi.post('/users', data),
  registerFreeTest: (id_user) => myApi.post(`/users/${id_user}/activate_free_test`),
  confirm_email: (user_email) => myApi.get(`/users/${user_email}/confirm_email`),
};

export default api;
